import React, { useContext, useState, useEffect, useRef } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import RatingTag from '../atom/RatingTag';

import { setReportToggle } from '../../actions/home';
import config from '../../config';
import PowerBiReport from '../atom/PowerBiReport';
import Tile from '../atom/Tile';
import TileBlock from '../atom/TileBlock';
import AuthContext from '../context/AuthContext';
import FilterContext from '../context/FilterContext';
import RSMTileContext from '../context/RSMTileContext';
import Filters from '../molecules/Filters';
import DashboardStyles from '../styles/DashboardStyles';
import LayoutStyles from '../styles/LayoutStyles';

const RSMDashboard = (props) => {
  const { tableReports, showAlert, personaId, accessToken } = props;
  const { reportUrl, centralId, newCountry, newRegion, newLocationType } = config;
  const filterContext = useContext(FilterContext);
  const { setLocationType, locationTypeData } = filterContext;
  const embedUrl = `${reportUrl}${centralId}`;
  const [changedFilterState, setChangedFilterState] = useState({
    location: [],
    country: [],
    region: [],
    locationType: [],
  });
  const { user, getCustomTilesTable, refreshTime, updateKPIOrder } = useContext(AuthContext);
  const { activeTile, setActiveTile, activeTab, setActiveTab } = useContext(RSMTileContext);
  const appInsights = useAppInsightsContext();
  const classes = DashboardStyles();
  const layoutClasses = LayoutStyles();
  const navigate = useNavigate();
  const [toggleRowStar, setToggleRowStar] = useState({});
  const [tileId, setTileId] = useState(null);
  const [tilePageName, setTilePageName] = useState(null);
  const [modalEmbedUrl, setModalEmbedUrl] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [kpiName, setKpiName] = useState(null);
  const [locationType, setLocationTypeData] = useState(null);
  const [innerKpiId, setInnerKpiId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [dragKpi, setDragKpi] = useState(false);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const timeoutRef = useRef(null);
  // Function for navigate to RSM Detail page
  const viewMore = () => {
    navigate(`/rsmDetails/${rowId}`, { state: { innerKpiId: innerKpiId } });
  };
  // Function for dispaly the PBI garaph
  const callPBI = (id, name, linked, kpi_id) => {
    const { _id, graphPageName } = linked;
    let selectedLocationType = locationType ? locationType : user.locationType;
    let filters = '';
    if (selectedLocationType === 'ALL') {
      filters = `&filter=${newCountry}country%20eq%20'${user?.country.trim()}'%20and%20${newRegion}region%20eq%20'${user?.region.trim()}'`;
    } else if (selectedLocationType !== null) {
      filters = `&filter=${newCountry}country%20eq%20'${user?.country.trim()}'%20and%20${newRegion}region%20eq%20'${user?.region.trim()}'%20and%20${newLocationType}location_type_description%20eq%20'${selectedLocationType}'`;
    }
    if (filters) {
      appInsights.trackEvent({
        name: 'KPI Report',
        properties: {
          kpiName: name,
          personaName: user.persona.name,
          refUri: window.location.href,
        },
      });
      setRowId(id);
      setKpiName(name);
      setTileId(_id);
      setInnerKpiId(kpi_id);
      setTilePageName(graphPageName);
      setModalEmbedUrl(`${embedUrl}`);
      setChangedFilterState({
        location: [],
        country: [user.country.trim()],
        region: [user?.region.trim()],
        multipleLocationType: selectedLocationType === 'ALL' ? [] : [selectedLocationType],
      });
    }
  };
  // Call back function for set the table data based on active tab (created for custom tiles reorder)
  useEffect(() => {
    if (!isEmpty(tableReports) && activeTab >= 0) {
      setTableData(tableReports[activeTab].data);
    }
  }, [tableReports, activeTab]);

  // Function for tile click
  const onTileClick = (report, id, name, linked, kpi_id) => (e) => {
    setActiveTile(report);
    callPBI(id, name, linked, kpi_id);
  };
  // Function for click on favorite icon and call the api then display the favorite.
  const onToggleRowStarClick = (dataType, id, value, kpiId) => async (e) => {
    e.preventDefault();
    let data = { ...toggleRowStar };
    data[dataType][kpiId] = !value;
    setToggleRowStar({ ...toggleRowStar, ...data });
    const respData = await setReportToggle({
      userId: user.id,
      reportId: id,
      fav: !value,
      personaId: personaId,
      kpiId: kpiId,
    });
    if (respData.error) {
      data[dataType][kpiId] = value;
      setToggleRowStar({ ...toggleRowStar, ...data });
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
        fontSize: '1rem',
      });
    }
  };
  // Function for activate the tab for tiles
  const handleChange = (e, tab) => {
    e.preventDefault();
    setActiveTile(null);
    setActiveTab(tab);
  };

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    setLocationTypeData(locationTypeData ? locationTypeData : user.locationType);
  }, [user]);

  // Callback function for set favorite data
  useEffect(() => {
    let favData = {
      table: {},
    };
    tableReports.map((report) => {
      report.data.map((r) => {
        Object.assign(favData['table'], { [r.reportDetail.kpi_id]: !!r.fav });
      });
    });
    setToggleRowStar({ ...toggleRowStar, ...favData });
  }, [tableReports]);

  // Display the tab data
  useEffect(() => {
    if (!isEmpty(tableReports) && activeTab >= 0 && activeTile === null) {
      let report = tableReports[activeTab]?.data[0];
      if (report) {
        callPBI(report.id, report.kpiName, report.linked, report.reportDetail.kpi_id);
      } else {
        setModalEmbedUrl(null);
      }
    } else if (activeTile) {
      let report = activeTile;
      callPBI(report.id, report.kpiName, report.linked, report.reportDetail.kpi_id);
    } else {
      setModalEmbedUrl(null);
    }
  }, [activeTab, tableReports, activeTile]);

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    if (locationType) {
      getCustomTilesTable(user, locationType);
    }
  }, [locationType, user]);

  // Function for set location type to local state
  const getLocationType = (locationType) => {
    setLocationTypeData(locationType);
    setLocationType(locationType);
  };

  // Function for reorder the custom tiles
  const handleDragSort = (e, index) => {
    const list = [...tableData];
    const item = list[dragItem.current];
    list.splice(dragItem.current, 1);
    list.splice(dragOverItem.current, 0, item);
    dragItem.current = dragOverItem.current;
    list.map((elem, index) => {
      return (elem.order = index + 1);
    });
    setTableData(list);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to update KPI order and get custom tiles after 10 seconds
    timeoutRef.current = setTimeout(async () => {
      const data = await updateKPIOrder(1, activeTab + 1, list, user);
      if (!data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'success',
        });
      }
      if (data.error) {
        showAlert({
          alertMessage: data.message,
          alertType: 'error',
        });
      }
      getCustomTilesTable(user, locationType);
    }, 5000);
  };
  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          countryLabel={true}
          dailyViewRefreshTime={true}
          refreshTime={refreshTime}
          locationTypeSingleDropDown={true}
          regionLabel={false}
          regionSingleDropDown={true}
          getLocationType={getLocationType}
          rsmDailyViewLocationTypeData={locationTypeData}
          showAlert={showAlert}
          showDragFilter={true}
          dragKpi={dragKpi}
          setDragKpi={setDragKpi}
        />
      </Grid>
      <Grid item container className={layoutClasses.rsmRightContainer}>
        <Grid
          item
          xl={12}
          xs={12}
          md={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={classes.customerService}
        >
          <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
            Customer Service
          </Typography>
          {!isEmpty(tableData) && (
            <Typography variant='h5' component={'h5'} style={{ display: 'flex' }}>
              <RatingTag ratingScale={1} ratingPoint={true} />
              <span style={{ fontSize: '20px', paddingTop: '5px', paddingLeft: '10px' }}>Favorites</span>
            </Typography>
          )}
        </Grid>

        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.RsmDashboardContainer}>
          {isEmpty(tableReports) && (
            <Grid item className={`${classes.noFavData}`} xl={12} xs={12} md={12}>
              No data available
            </Grid>
          )}
          {/* Table Code */}
          <Grid item xl={12} xs={12} md={12} className={`${classes.rsmTableStyle}`}>
            <Box>
              <Box sx={{ borderColor: 'divider', mb: 1 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                  variant='scrollable'
                  value={activeTab}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  className={classes.rsmTabs}
                >
                  {tableReports.map((elem, index) => (
                    <Tab
                      style={{ margin: '0px 25px 0px 0px' }}
                      label={elem.name}
                      key={elem.id}
                      id={`tab-${index}`}
                      className={classes.tabLabel}
                    />
                  ))}
                </Tabs>
                {!isEmpty(tableData) && (
                  <Typography variant='h5' component={'h5'} style={{ display: 'flex' }}>
                    <span
                      style={{
                        backgroundColor: 'blue',
                        border: '5px solid blue',
                        borderRadius: '5px',
                        width: '50px',
                        height: '3px',
                        marginTop: '13px',
                      }}
                    ></span>
                    <span style={{ fontSize: '20px', paddingTop: '5px', paddingLeft: '10px' }}>PGT Remediated</span>
                  </Typography>
                )}
              </Box>
              <Grid item container className={`${classes.tilesStyle} ${classes.rsmTilesStyle}`} xl={12} xs={12} md={12}>
                {tableReports.map(
                  (elem, key) =>
                    !isEmpty(tableData) &&
                    tableData.map((report, index) => {
                      return (
                        <Grid
                          key={report.id}
                          item
                          className={`${
                            report.pgtData === 'Yes' ? classes.pgt_iframeContainer : classes.iframeContainer
                          } 
                         ${classes.rsmIframeContainer} 
                         ${activeTab === key ? classes.rsmDisplayTab : ''} 
                         ${
                           report.kpiName === kpiName
                             ? report.pgtData === 'Yes'
                               ? classes.pgt_tileActive
                               : classes.tileActive
                             : ''
                         }`}
                          draggable={dragKpi}
                          onDragStart={dragKpi ? (e) => (dragItem.current = index) : undefined}
                          onDragEnter={dragKpi ? (e) => (dragOverItem.current = index) : undefined}
                          onDragEnd={dragKpi ? handleDragSort : undefined}
                        >
                          {report.reportDetail.Design_Flag !== 2 ? (
                            <Tile
                              key={`tile-${report.id}`}
                              kpiName={report.kpiName}
                              reportDetail={report.reportDetail}
                              ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                              onToggleRowStarClick={onToggleRowStarClick(
                                'table',
                                report.id,
                                toggleRowStar['table'][report.reportDetail.kpi_id],
                                report.reportDetail.kpi_id,
                              )}
                              dragKpi={dragKpi}
                              user={user}
                              KpiId={report.reportDetail.kpi_id}
                              onTileClick={onTileClick(
                                report,
                                report.id,
                                report.kpiName,
                                report.linked,
                                report.reportDetail.kpi_id,
                              )}
                            />
                          ) : (
                            <TileBlock
                              key={`tileblock-${report.id}`}
                              kpiName={report.kpiName}
                              reportDetail={report.reportDetail}
                              ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                              onToggleRowStarClick={onToggleRowStarClick(
                                'table',
                                report.id,
                                toggleRowStar['table'][report.reportDetail.kpi_id],
                                report.reportDetail.kpi_id,
                              )}
                              dragKpi={dragKpi}
                              user={user}
                              KpiId={report.reportDetail.kpi_id}
                              onTileClick={onTileClick(
                                report,
                                report.id,
                                report.kpiName,
                                report.linked,
                                report.reportDetail.kpi_id,
                              )}
                            />
                          )}
                        </Grid>
                      );
                    }),
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {modalEmbedUrl && (
          <Grid item container xl={12} xs={12} md={12} className={`${classes.RsmPBIContainer}`}>
            <Grid item xl={12} xs={12} md={12}>
              <Typography variant='h6' className={classes.powerBIKPIName}>
                {kpiName}
              </Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Box
                xs={12}
                className={`${classes.powerBiWidget}`}
                style={{ height: innerKpiId === 'WAR008' ? '350px' : '260px' }}
              >
                <PowerBiReport
                  accessToken={accessToken}
                  id={tileId}
                  KpiId={innerKpiId}
                  embedUrl={modalEmbedUrl}
                  pageName={tilePageName}
                  country={changedFilterState.country}
                  region={changedFilterState.region}
                  location={changedFilterState.location}
                  multipleLocationType={changedFilterState.multipleLocationType}
                />
              </Box>
            </Grid>
            <Grid item xl={12} xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid
                item
                xl={4}
                xs={4}
                md={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                className={classes.rankingsButton}
                onClick={viewMore}
              >
                <Typography className={classes.rankText}>
                  View Rankings
                  <KeyboardDoubleArrowRightTwoToneIcon />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

RSMDashboard.propTypes = {};

export default RSMDashboard;
