import React, { useContext, useState, useEffect } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import { some as lodashSome, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { setReportToggle } from '../../actions/home';
import Dropdown from '../atom/Dropdown';
import Modal from '../atom/Modal';
import PowerBiReport from '../atom/PowerBiReport';
import TabBlock from '../atom/TabBlock';
import Tabular from '../atom/Tabular';
import Tile from '../atom/Tile';
import TileBlock from '../atom/TileBlock';
import AuthContext from '../context/AuthContext';
import FavoritesContext from '../context/FavoritesContext';
import Filters from '../molecules/Filters';
import DashboardStyles from '../styles/DashboardStyles';
import LayoutStyles from '../styles/LayoutStyles';
import FilterContext from '../context/FilterContext';

const FavoritesDashboard = (props) => {
  const { reports, tableReports, accessToken, showAlert, personaId } = props;
  const { user, refreshTime } = useContext(AuthContext);
  const { getFavorites } = useContext(FavoritesContext);
  const filterContext = useContext(FilterContext);
  const { setSingleLocation, singleLocation } = filterContext;
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  const classes = DashboardStyles();
  const layoutClasses = LayoutStyles();
  const [defaultView, setdefaultView] = useState('Daily View');
  const [toggleRowStar, setToggleRowStar] = useState({});
  const [findFavourite, setFindFavourite] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [tileId, setTileId] = useState(null);
  const [tilePageName, setTilePageName] = useState(null);
  const [modalEmbedUrl, setModalEmbedUrl] = useState(null);
  const [location, setLocation] = useState('');
  const [kpiId, setKpiId] = useState('');

  const onSelect = (value) => {
    if (value.label === 'Daily View') {
      navigate('/favorites/dailyView');
    } else {
      navigate('/favorites/historicalView');
    }
  };
  // Function for display PBI graph into the modal
  const onTileClick = (linked, name, kpiId) => (e) => {
    e.preventDefault();
    const { embedUrl, _id, pageName } = linked;
    setTileId(_id);
    setKpiId(kpiId);
    setTilePageName(pageName);
    setModalEmbedUrl(embedUrl);
    setModalOpen(true);
    appInsights.trackEvent({
      name: 'KPI Report',
      properties: {
        kpiName: name,
        personaName: user.persona.name,
        refUri: window.location.href,
      },
    });
  };
  // Function for close modal
  const handleClose = () => {
    setModalOpen(false);
  };

  // Function for click on favorite icon and call the api then display the favorite.
  const onToggleRowStarClick = (dataType, id, value, kpiId) => async (e) => {
    e.preventDefault();
    let data = { ...toggleRowStar };
    data['tile'][kpiId] = !value;
    data['table'][kpiId] = !value;
    setToggleRowStar({ ...toggleRowStar, ...data });

    const respData = await setReportToggle({
      userId: user.id,
      reportId: id,
      fav: !value,
      personaId: personaId,
      kpiId: kpiId,
    });
    if (respData.error) {
      data['tile'][kpiId] = value;
      data['table'][kpiId] = value;
      setToggleRowStar({ ...toggleRowStar, ...data });
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
        fontSize: '1rem',
      });
    }
  };

  // Function for activate the tab for tiles
  const handleChange = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  useEffect(() => {
    setLocation(singleLocation == '' ? user.location : singleLocation);
  }, [singleLocation]);

  // Callback function for call the api for tiles and tabular data
  useEffect(() => {
    if (location) {
      getFavorites(user, '', location);
    }
  }, [location]);

  // Callback function for set favorite data
  useEffect(() => {
    let favData = {
      tile: {},
      table: {},
    };
    reports.map((report) => {
      Object.assign(favData['tile'], { [report.reportDetail.kpi_id]: !!report.fav });
    });
    tableReports.map((report) => {
      report.data.map((r) => {
        Object.assign(favData['table'], { [r.reportDetail.kpi_id]: !!r.fav });
      });
    });
    setToggleRowStar({ ...toggleRowStar, ...favData });
  }, [reports, tableReports]);

  // Callback function for dispaly the No favourites selected or No data available
  useEffect(() => {
    let findTileFav = lodashSome(toggleRowStar['tile'], (v) => v === true);
    let findTableFav = lodashSome(toggleRowStar['table'], (v) => v === true);
    setFindFavourite({
      ...findFavourite,
      tile: findTileFav,
      table: findTableFav,
      all: findTileFav ? findTileFav : findTableFav,
    });
  }, [toggleRowStar]);

  // Function for set location to local state
  const getLocation = (location) => {
    setLocation(location);
    setSingleLocation(location);
  };

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          countryLabel={true}
          locationLabel={user.persona.layoutId !== 1 ? true : false}
          locationSingleDropDown={user.persona.layoutId === 1 ? true : false}
          dailyViewRefreshTime={true}
          refreshTime={refreshTime}
          getLocation={getLocation}
          showAlert={showAlert}
        />
      </Grid>
      <Grid item container className={layoutClasses.rightContainer}>
        <Grid item xl={12} xs={12} md={12} container spacing={0} className={layoutClasses.favoriteDropdownContainer}>
          <Grid item xl={6} xs={12} md={6} className={classes.customerService}>
            <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
              Customer Service
            </Typography>
            <Dropdown
              options={[
                { label: 'Daily View', value: 'Daily View' },
                { label: 'Historical View', value: 'Historical View' },
              ]}
              isOptionEqualToValue={(option, value) => option.value === value}
              getOptionLabel={(option) => (option.value ? option.value : defaultView)}
              value={defaultView}
              onChange={(event, value) => onSelect(value)}
              popperbottom={41}
              className={layoutClasses.favoriteDropdownList}
            />
          </Grid>
          <Grid item container spacing={0} xl={6} xs={6} md={6} className={classes.infoContainer}>
            {!isEmpty(tableReports) && (
              <Typography variant='h5' component={'h5'} style={{ display: 'flex' }}>
                <span
                  style={{
                    backgroundColor: 'blue',
                    border: '5px solid blue',
                    borderRadius: '5px',
                    width: '50px',
                    height: '3px',
                    marginTop: '13px',
                  }}
                ></span>
                <span style={{ fontSize: '20px', paddingTop: '5px', paddingLeft: '10px' }}>PGT Remediated</span>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.dashboardContainer}>
          <Grid
            item
            className={`${classes.noFavData} ${findFavourite['all'] ? classes.favoritePage : ''}`}
            xl={12}
            xs={12}
            md={12}
          >
            No favorites selected
          </Grid>

          {/* tiles code */}
          <Grid item className={classes.tilesStyle} xl={5} xs={5} md={5}>
            {tableReports.map((elem) =>
              elem.data.map((report, rIndex) => {
                return (
                  <Grid
                    key={report.id}
                    item
                    className={`${report.pgtData === 'Yes' ? classes.pgt_iframeContainer : classes.iframeContainer} ${
                      !toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''
                    }`}
                  >
                    {report.reportDetail.Design_Flag !== 2 ? (
                      <>
                        <Tile
                          key={`tile-${report.id}`}
                          kpiName={report.kpiName}
                          reportDetail={report.reportDetail}
                          ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                          onToggleRowStarClick={onToggleRowStarClick(
                            'table',
                            report.id,
                            toggleRowStar['table'][report.reportDetail.kpi_id],
                            report.reportDetail.kpi_id,
                          )}
                          user={user}
                          KpiId={report.reportDetail.kpi_id}
                          onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                        />
                      </>
                    ) : (
                      <TileBlock
                        key={`tileblock-${report.id}`}
                        kpiName={report.kpiName}
                        reportDetail={report.reportDetail}
                        ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                        onToggleRowStarClick={onToggleRowStarClick(
                          'table',
                          report.id,
                          toggleRowStar['table'][(report.reportDetail.kpi_id, report.reportDetail.kpi_id)],
                          report.reportDetail.kpi_id,
                        )}
                        user={user}
                        KpiId={report.reportDetail.kpi_id}
                        onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                      />
                    )}
                  </Grid>
                );
              }),
            )}
          </Grid>
          {/* Table Code */}
          <Grid
            item
            xl={7}
            xs={7}
            md={7}
            className={`${classes.tableStyle} ${!findFavourite['table'] ? classes.favoritePage : ''}`}
          >
            <Box>
              <Box sx={{ borderColor: 'divider' }}>
                <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  className={classes.tabs}
                >
                  {tableReports.map((elem, index) => (
                    <Tab
                      style={{ margin: '0px 25px 0px 0px' }}
                      label={elem.name}
                      key={elem.id}
                      id={`tab-${index}`}
                      className={classes.tabLabel}
                    />
                  ))}
                </Tabs>
              </Box>
              {tableReports.map((elem, index) => {
                return (
                  <Grid
                    key={elem.id}
                    className={`${classes.tabContainer} ${activeTab === index ? classes.displayTab : ''}`}
                  >
                    <TableContainer key={`container-${elem.id}`}>
                      <Table aria-label='table' key={`table-${elem.id}`}>
                        <TableHead>
                          <TableRow className={classes.tableRows}>
                            <TableCell align='left' className={`${classes.tableHeader} ${classes.firstHeading}`}>
                              METRICS
                            </TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.secondHeading}`}>
                              LAST 24 HRS
                            </TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.thirdHeading}`}>
                              WTD
                            </TableCell>
                            <TableCell
                              align='left'
                              className={`${classes.tableHeader} ${classes.fourthHeading}`}
                            ></TableCell>
                            <TableCell align='center' className={`${classes.tableHeader} ${classes.TrendHeading}`}>
                              TREND
                            </TableCell>
                            <TableCell
                              align='left'
                              className={`${classes.tableHeader} ${classes.ratingHeading}`}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {elem.data.map((report, index) => (
                            <TableRow
                              hover
                              className={`${
                                report.pgtData === 'Yes' ? classes.pgt_tableBodyRows : classes.tableBodyRows
                              }`}
                              onClick={onTileClick}
                            >
                              {report.reportDetail.Design_Flag !== 2 ? (
                                <Tabular
                                  key={`tabular-${report.id}`}
                                  kpiName={report.kpiName}
                                  reportDetail={report.reportDetail}
                                  ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                  report={report}
                                  toggleRowStar={toggleRowStar}
                                  onToggleRowStarClick={onToggleRowStarClick}
                                  favClassName={
                                    !toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''
                                  }
                                  user={user}
                                  KpiId={report.reportDetail.kpi_id}
                                  onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                                />
                              ) : (
                                <TabBlock
                                  key={`tabblock-${report.id}`}
                                  kpiName={report.kpiName}
                                  reportDetail={report.reportDetail}
                                  ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
                                  report={report}
                                  toggleRowStar={toggleRowStar}
                                  onToggleRowStarClick={onToggleRowStarClick}
                                  favClassName={
                                    !toggleRowStar['table'][report.reportDetail.kpi_id] ? classes.favoritePage : ''
                                  }
                                  user={user}
                                  KpiId={report.reportDetail.kpi_id}
                                  onTileClick={onTileClick(report.linked, report.kpiName, report.reportDetail.kpi_id)}
                                />
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Modal open={modalOpen} handleClose={handleClose}>
          <Box className={classes.boxStyle}>
            <PowerBiReport
              accessToken={accessToken}
              id={tileId}
              KpiId={kpiId}
              embedUrl={modalEmbedUrl}
              pageName={tilePageName}
              country={[user.country.trim()]}
              location={[singleLocation == '' ? user.location : singleLocation]}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

FavoritesDashboard.propTypes = {};

export default FavoritesDashboard;
