import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { some as _some, isEmpty, find } from 'lodash';
import { pingUser, postUser } from '../../actions/user';
import Dropdown from '../atom/Dropdown';
import FilterContext from '../context/FilterContext';
import SwitchUserStyles from '../styles/SwitchUserStyles';
import PepsicoLogo from '../assets/Pepsico_logo.svg';
const Users = (props) => {
  // Variables
  const { switchUserLinks, user, showAlert } = props;
  const filterContext = useContext(FilterContext);
  const { location, country, getLocationsData, region, getRegionsData, locationType, getLocationTypesData } =
    filterContext;
  const classes = SwitchUserStyles();
  const reactLocation = useLocation();
  const navigate = useNavigate();
  const [personaId, setPersonaId] = useState({ id: '', name: '', layoutId: '' });
  const [defaultCountry, setDefaultCountry] = useState(user.country);
  const [defaultLocation, setDefaultLocation] = useState(user.location);
  const [defaultRegion, setDefaultRegion] = useState(user.region);
  const [defaultLocationType, setDefaultLocationType] = useState(user.locationType);
  const [disableButton, setDisableButton] = useState(true);
  const [singleLocationTypeOption, setSingleLocationTypeOption] = useState([]);
  const [locationOption, setlocationOption] = useState(user.country ? true : false);
  //  Functions

  // Set the initial value in local state
  const setAllData = (layoutId, persona) => {
    setDefaultCountry(user.country);
    if (layoutId === 1) {
      user.location ? setDefaultLocation(user.location) : setDefaultLocation(null);
      getLocationsData(user.country, undefined, undefined, persona ? persona : user.personaId);
      setDefaultLocationType(null);
      setDefaultRegion(null);
      user.country ? setlocationOption(true) : setlocationOption(false);
    } else if (layoutId === 2) {
      setDefaultRegion(user.region);
      setDefaultLocationType(user.locationType);
      getRegionsData(user.country, persona ? persona : user.personaId);
      setDefaultLocation(null);
      getLocationTypesData(user.country, [user.region]);
    } else if (layoutId === 3) {
      setDefaultRegion(null);
      setDefaultLocationType(null);
      setDefaultLocation(null);
    }
  };

  // Callback function for navigation and call setAllData function based on location and user
  useEffect(() => {
    if (user.personaId && reactLocation.pathname === '/') {
      // navigate('/home');
      navigate(`${user.persona.layoutId === 3 ? '/nationalHistoricalView' : '/home'}`);
    } else if (user.personaId && reactLocation.pathname === '/editProfile') {
      setAllData(user.persona.layoutId, user.personaId);
    }
  }, [navigate, reactLocation, user]);

  useEffect(() => {
    location ? setlocationOption(true) : setlocationOption(false);
  }, [location]);

  // Get the persona name
  useEffect(() => {
    if (!isEmpty(switchUserLinks) && user.personaId) {
      let data = find(switchUserLinks, { id: user.personaId });
      setPersonaId({ id: data.id, name: data.name, layoutId: data.layoutId });
    }
  }, [switchUserLinks]);

  // Function for add ALL option into location type list
  useEffect(() => {
    let newData = [
      {
        label: 'ALL',
        value: 'ALL',
      },
      ...locationType,
    ];
    setSingleLocationTypeOption(newData);
  }, [locationType]);

  // Save button is disabled and enabled
  useEffect(() => {
    if (personaId.layoutId === 1) {
      if (defaultLocation !== null && defaultCountry !== null && personaId.id !== undefined) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else if (personaId.layoutId === 2) {
      if (!isEmpty(defaultLocationType) && defaultRegion !== null && defaultCountry !== null && personaId.id !== null) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else if (personaId.layoutId === 3) {
      if (defaultCountry !== null && personaId.id !== null) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [defaultCountry, defaultLocation, defaultLocationType, defaultRegion, personaId]);

  // Function for set persona detail and call setAllData function
  const onSelectPersona = (value) => {
    setPersonaId({
      id: value.id,
      name: value.name,
      layoutId: value.layoutId,
    });
    setAllData(value.layoutId, value.id);
    setDefaultLocation(null);
  };

  // Save and Update the user details
  const onSaveHandler = useCallback(
    async (event) => {
      event.preventDefault();
      const userData = {
        email: user.email,
        personaId: personaId.id,
        country: defaultCountry.trim(),
        location: defaultLocation ? defaultLocation : null,
        region: defaultRegion ? defaultRegion.trim() : null,
        locationType: defaultLocationType ? defaultLocationType.trim() : null,
      };
      const respData = await postUser(userData);
      if (respData.error) {
        showAlert({
          alertMessage: respData.message,
          alertType: 'error',
        });
      } else {
        pingUser({ userName: user.email });
        window.location.href = `${personaId.layoutId === 3 ? '/nationalHistoricalView' : '/home'}`;
      }
    },
    [defaultCountry, defaultLocation, navigate, personaId, user, defaultRegion, defaultLocationType],
  );

  // If user don't submit the detail and click on skip button
  const onSkipHandler = useCallback(() => {
    navigate(`${user.persona.layoutId === 3 ? '/nationalHistoricalView' : '/home'}`);
  }, [navigate]);

  const onSelectCountry = (country) => {
    if (country.country) {
      if (personaId.layoutId === 1) {
        setDefaultLocation(null);
        getLocationsData(country.country, undefined, undefined, personaId.id);
      } else if (personaId.layoutId === 2) {
        setDefaultLocationType(null);
        setDefaultRegion(null);
        getRegionsData(country.country, personaId.id);
      } else if (personaId.layoutId === 3) {
        setDefaultLocationType(null);
        setDefaultRegion(null);
        setDefaultLocation(null);
      }
      setDefaultCountry(country.country);
    }
  };

  // Set location data into the local state
  const onSelectLocation = (location) => {
    if (location.value) {
      setDefaultLocation(location.value);
    }
  };

  // Set region data into the local state
  const onSelectRegion = (region) => {
    if (region.value) {
      setDefaultRegion(region.value);
      setDefaultLocationType(null);
      getLocationTypesData(defaultCountry, [region.value]);
    }
  };

  // Call Location Type select function
  const onSelectLocationType = (locationType) => {
    if (locationType.value) {
      setDefaultLocationType(locationType.value);
    } else {
      setDefaultLocationType(null);
    }
  };

  return (
    <>
      <Box xs={12} container item spacing={1} className={classes.rootContainer}>
        <Grid xs={12} item className={classes.logoContainer}>
          <img src={PepsicoLogo} alt='Pepsico Logo' className={classes.logImage} />
          <Typography noWrap variant='h5' className={classes.logName}>
            Customer Service Hub
          </Typography>
        </Grid>
        <Grid container item spacing={2} xs={12} className={classes.designationContainer}>
          <Grid item xl={2} md={2} xs={12}>
            <Typography variant='h6' className={classes.designation}>
              Select your Role
            </Typography>
          </Grid>
          <Grid item xl={6} md={6} xs={12}>
            <Dropdown
              options={switchUserLinks}
              getOptionLabel={(option) => (option.name ? option.name : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => onSelectPersona(value)}
              className={classes.dropdown}
              value={personaId}
              popperbottom={47}
            />
          </Grid>
          <Grid item xs={12} className={classes.filterLabel}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.designation}>
                Set filters
              </Typography>
            </Grid>
            <Grid container item spacing={2} xs={12} className={classes.dropdownSection}>
              <Grid item xl={3} md={3} xs={12}>
                <Dropdown
                  label='Country'
                  options={country}
                  getOptionLabel={(option) => (option.country ? option.country : defaultCountry)}
                  isOptionEqualToValue={(option, value) => option.country === value}
                  onChange={(e, value) => onSelectCountry(value)}
                  className={classes.dropdown}
                  value={defaultCountry}
                  popperbottom={47}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12}>
                <Dropdown
                  label='Region'
                  options={region}
                  getOptionLabel={(option) => (option.value ? option.value : defaultRegion)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  onChange={(_e, value) => onSelectRegion(value)}
                  className={classes.dropdown}
                  value={defaultRegion}
                  disabled={personaId.layoutId === 2 ? false : true}
                  popperbottom={47}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12}>
                <Dropdown
                  label='Location Type'
                  options={singleLocationTypeOption}
                  getOptionLabel={(option) => (option.value ? option.value : defaultLocationType)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  onChange={(_e, value) => onSelectLocationType(value)}
                  className={classes.dropdown}
                  value={defaultLocationType}
                  disabled={personaId.layoutId === 2 ? false : true}
                  popperbottom={47}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12}>
                <Dropdown
                  label='Location'
                  options={location}
                  onChange={(_e, value) => onSelectLocation(value)}
                  className={classes.dropdown}
                  value={defaultLocation}
                  disabled={personaId.layoutId === 1 && locationOption ? false : true}
                  popperbottom={47}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item className={classes.footerContainer}>
          {/* Submit Button */}
          <Button
            variant='contained'
            size='large'
            disabled={disableButton}
            onClick={onSaveHandler}
            className={classes.saveButton}
          >
            Save & Continue
          </Button>
          {/* Skip button will be appear when user edit theme profile */}
          {reactLocation.pathname === '/editProfile' ? (
            <Button onClick={onSkipHandler} variant='text' className={classes.skipButton}>
              Skip for now
            </Button>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Users;
