/* 
This file creates a reducer function for Filters used in the application.
It will have initial state and Filters types used in the application.
*/
import React, { useReducer } from 'react';
import { isEmpty } from 'lodash';

import { getFilterCountry, getFilterLocation, getFilterRegion, getFilterLocationType } from '../../actions/home';

import FilterContext from './FilterContext';

// Types
const GET_COUNTRY_DATA = 'GET_COUNTRY_DATA';
const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
const GET_REGION_DATA = 'GET_REGION_DATA';
const GET_LOCATIONTYPE_DATA = 'GET_LOCATIONTYPE_DATA';
const SET_LOCATION = 'SET_LOCATION';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_REGION = 'SET_REGION';
const SET_LOCATIONTYPE = 'SET_LOCATIONTYPE';
const SET_SINGLE_LOCATION = 'SET_SINGLE_LOCATION';
const SET_SINGLE_REGION = 'SET_SINGLE_REGION';
const SET_MULTIPLE_LOCATION_TYPE = 'SET_MULTIPLE_LOCATION_TYPE';

// Reducer
const FilterReducer = (state, action) => {
  switch (action.type) {
    case GET_COUNTRY_DATA:
      return {
        ...state,
        country: action.payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        countryData: action.payload,
      };
    case GET_LOCATION_DATA:
      return {
        ...state,
        location: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        locationData: action.payload,
      };
    case SET_SINGLE_LOCATION:
      return {
        ...state,
        singleLocation: action.payload,
      };
    case SET_SINGLE_REGION:
      return {
        ...state,
        singleRegion: action.payload,
      };
    case GET_REGION_DATA:
      return {
        ...state,
        region: action.payload,
      };
    case SET_REGION:
      return {
        ...state,
        regionData: action.payload,
      };
    case GET_LOCATIONTYPE_DATA:
      return {
        ...state,
        locationType: action.payload,
      };
    case SET_LOCATIONTYPE:
      return {
        ...state,
        locationTypeData: action.payload,
      };

    case SET_MULTIPLE_LOCATION_TYPE:
      return {
        ...state,
        multipleLocationTypeData: action.payload,
      };

    default:
      return state;
  }
};

const FilterStates = ({ children }) => {
  // Initial state
  const initialState = {
    locationData: {},
    countryData: {},
    regionData: {},
    locationTypeData: '',
    location: [],
    country: [],
    region: [],
    locationType: [],
    singleLocation: '',
    singleRegion: '',
  };
  const [state, dispatch] = useReducer(FilterReducer, initialState);

  // Function to get and set country list
  const getCountryData = async () => {
    const data = await getFilterCountry();
    dispatch({
      type: GET_COUNTRY_DATA,
      payload: data && data.filter((countyrData) => countyrData.country !== null),
    });
  };

  // Function to get and set location list
  const getLocationsData = async (country, region, locationType, personaId, reportId, allSelected) => {
    let data;
    data = await getFilterLocation(country, region, locationType, personaId, reportId, allSelected);
    dispatch({
      type: GET_LOCATION_DATA,
      payload: data?.data?.filter((locationData) => locationData.value !== null),
    });
  };
  // Function to get and set region list
  const getRegionsData = async (country, personaId) => {
    let data;
    if (!country && !personaId) {
      data = await getFilterRegion();
    }
    data = await getFilterRegion(country, personaId);
    dispatch({
      type: GET_REGION_DATA,
      payload: data.filter((regionData) => regionData.value !== null),
    });
  };

  // Function to get and set location type list
  const getLocationTypesData = async (country, region, innerkpiId) => {
    let data;
    data = await getFilterLocationType(country, region, innerkpiId);
    dispatch({
      type: GET_LOCATIONTYPE_DATA,
      payload: data?.data?.filter((locationTypeData) => locationTypeData.value !== null),
    });
  };
  // Function to set selected location
  const setLocation = async (locationData) => {
    if (!isEmpty(locationData)) {
      dispatch({
        type: SET_LOCATION,
        payload: locationData,
      });
    } else {
      dispatch({
        type: SET_LOCATION,
        payload: [],
      });
      dispatch({
        type: GET_LOCATION_DATA,
        payload: [],
      });
    }
  };

  // Function to set selected single location
  const setSingleLocation = async (locationData) => {
    dispatch({
      type: SET_SINGLE_LOCATION,
      payload: locationData,
    });
  };

  // Function to set selected single region
  const setSingleRegion = async (selectedregion) => {
    dispatch({
      type: SET_SINGLE_REGION,
      payload: selectedregion,
    });
  };

  // Function to set selected region
  const setRegion = async (regionData) => {
    if (regionData) {
      dispatch({
        type: SET_REGION,
        payload: regionData,
      });
    } else {
      dispatch({
        type: SET_REGION,
        payload: {},
      });
    }
  };

  // Function to set selected location type
  const setLocationType = async (locationTypeData) => {
    if (locationTypeData) {
      dispatch({
        type: SET_LOCATIONTYPE,
        payload: locationTypeData,
      });
    } else {
      dispatch({
        type: SET_LOCATIONTYPE,
        payload: {},
      });
    }
  };

  // Function to set selected location type
  const setMultipleLocationTypeData = async (multipleLocationTypeData) => {
    if (multipleLocationTypeData) {
      dispatch({
        type: SET_MULTIPLE_LOCATION_TYPE,
        payload: multipleLocationTypeData,
      });
    } else {
      dispatch({
        type: SET_MULTIPLE_LOCATION_TYPE,
        payload: [],
      });
    }
  };
  // Function to set selected country
  const setCountry = async (countryDta) => {
    if (countryDta) {
      dispatch({
        type: SET_COUNTRY,
        payload: countryDta,
      });
    } else {
      dispatch({
        type: SET_COUNTRY,
        payload: {},
      });
    }
  };

  return (
    <FilterContext.Provider
      value={{
        location: state.location,
        region: state.region,
        country: state.country,
        locationType: state.locationType,
        locationData: state.locationData,
        countryData: state.countryData,
        regionData: state.regionData,
        locationTypeData: state.locationTypeData,
        singleLocation: state.singleLocation,
        singleRegion: state.singleRegion,
        multipleLocationTypeData: state.multipleLocationTypeData,
        getCountryData,
        getLocationsData,
        getLocationTypesData,
        getRegionsData,
        setRegion,
        setLocation,
        setCountry,
        setLocationType,
        setSingleLocation,
        setSingleRegion,
        setMultipleLocationTypeData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterStates;
