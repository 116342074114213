import { makeStyles } from '@mui/styles';

const NationalViewHistoricalDashboardStyles = makeStyles((theme) => ({
  customerService: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  tabHeading: {
    padding: '0px 0px 0px 0px',
  },
  timePeriodContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timePeriodContain: {
    color: '#1C1C1C',
    fontSize: '1.2rem',
    opacity: 0.6,
  },
  dashboardContainer: {
    padding: '40px 0px 0px 0px',
  },
  RsmDashboardContainer: {
    padding: '0px 0px 0px 0px',
  },
  graphLegendPagination: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
  },

  tilesStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    height: 'fit-content',
    position: 'relative',
  },
  rsmTilesStyle: {
    gap: 8,
  },
  iframeContainer: {
    width: '100%',
    minWidth: 315,
    maxWidth: 315,
    margin: '2px',
    position: 'relative',
    minHeight: 165,
    paddingRight: '12px',
    maxHeight: 165,
    border: '1px solid #e3e0d8',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    //
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '9px', // Total width for the right "border"
      backgroundColor: '#FFFFFF', // Initial white color
      borderRadius: '0 10px 10px 0',
    },
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgb(38, 105, 169)', // Changes all borders to blue
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      '&::after': {
        background: 'linear-gradient(to right, #FFFFFF 94.67%, rgb(38, 105, 169) 5.33%)', // 11px white, 1px blue
      },
    },
  },
  pgt_iframeContainer: {
    width: '100%',
    minWidth: 315,
    maxWidth: 315,
    margin: '2px',
    position: 'relative',
    // height: '100%',
    minHeight: 165,
    maxHeight: 165,
    border: '1px solid #e3e0d8',
    borderRight: '12px solid rgb(38, 105, 169)',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgb(38, 105, 169)',
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      borderRight: '12px solid rgb(38, 105, 169)',
    },
  },
  tileActive: {
    position: 'relative',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
    top: 0,
    right: -1,
    height: '100%',
    width: '9px', // Creates a 12px border effect on the right
    border: '1px solid rgb(38, 105, 169)', // Blue color for the right side
    borderRadius: '10px', // Optional: adjust if rounding is needed
  },
  pgt_tileActive: {
    border: '1px solid rgb(38, 105, 169)',
    borderRight: '12px solid rgb(38, 105, 169)',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
  },
  tableStyle: {
    padding: '0px 25px 0px 15px',
    background: '#ffffff',
    height: 'auto',
  },
  rsmTableStyle: {
    height: 'auto',
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },
  rsmTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },

  tabLabel: {
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    color: 'black',
    textTransform: 'initial',
  },

  rsmIframeContainer: {
    display: 'none',
    minWidth: 308,
    maxWidth: 308,
    '@media screen and (min-width: 800px)': {
      minWidth: 385,
      maxWidth: 385,
    },
    '@media screen and (min-width: 1024px)': {
      minWidth: 391,
      maxWidth: 391,
    },
    '@media screen and (min-width: 1152px)': {
      minWidth: 331,
      maxWidth: 331,
    },
    '@media screen and (min-width: 1280px)': {
      minWidth: 331,
      maxWidth: 331,
    },
    '@media screen and (min-width: 1360px)': {
      minWidth: 307,
      maxWidth: 307,
    },
    '@media screen and (min-width: 1366px)': {
      minWidth: 307,
      maxWidth: 307,
    },
  },
  rsmDisplayTab: {
    display: 'block',
  },

  favoritePage: {
    display: 'none !important',
  },

  noDataAvailable: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },

  noDataAvailableGraph: {
    marginTop: 50,
  },

  RsmPBIContainer: {
    marginTop: -5,
  },
  RsmPBIContainerSummary: {
    marginTop: -5,
  },
  powerBIKPIName: {
    padding: '10px 0px 6px 0px',
    fontSize: '1.3rem',
    color: '#3D4043',
    fontWeight: '700',
  },
  viewMore: {
    fontSize: '1.2rem',
    color: '#0056C8',
    fontWeight: 'bold',
    textAlign: 'right',
    textDecoration: 'underline',
    marginTop: 4,
    cursor: 'pointer',
  },
  graphLegendContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    marginTop: 6,
    marginRight: 26,
  },
  graphScrollButton: {
    cursor: 'pointer',
  },
  graphLegends: {
    color: '#004D8A',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  targetLegends: {
    color: '#F7921D',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    fontColor: '#004D8A',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  graphContainer: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D8D9DB',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
  },
  powerBiWidget: {
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollBehavior: 'smooth',
  },
  dropdown: {
    marginTop: 4,
    '& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
      margin: 0,
      marginRight: 2,
      height: 22,
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'rgb(243, 248, 255)',
      border: '1px solid #eaeaea',
      padding: 0,
      paddingLeft: 4,
      borderRadius: 0,
      '& .MuiAutocomplete-input': {
        fontSize: '1.2rem',
        padding: 10,
        color: '#004D8A',
      },

      '& fieldset': {
        fontSize: '1rem',
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
  noFavData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  infoContainer: {
    display: 'flex',
    height: '35px',
    justifyContent: 'right',
    cursor: 'pointer',
    padding: '0px',
  },
  exportContainer: {
    display: 'flex',
    justifyContent: 'right',
    cursor: 'pointer',
    marginTop: '5px',
    height: '35px',
    padding: '0px',
  },
  descriptionIcon: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    border: '1px solid rgb(25, 118, 210)',
    padding: '4px 4px 4px 4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: 'white',
  },

  rankingsButton: {
    marginTop: '2px',
    border: '1px solid rgb(25, 118, 210)',
    padding: '4px 4px 4px 15px',
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    maxWidth: '200px',
    cursor: 'pointer',
  },

  rankText: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
  },

  iconStyling: {
    height: '20px',
    width: '20px',
    paddingRight: '5px',
  },
  screenshot: {
    width: '100%',
    minWidth: 315,
    maxWidth: 315,
    position: 'relative',
    marginBottom: 100,
    minHeight: 165,
    maxHeight: 165,
    border: '1px solid #e3e0d8',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    border: 'none',
    '& .onHoverHideL4W': {
      display: 'none',
      opacity: 0,
    },
    '& .onHoverDisplayL4W': {
      display: 'contents',
      opacity: 1,
      transition: 'opacity 1s linear',
    },
    '& .onHoverDisplay': {
      display: 'block',
    },
    '& .mainTileContainer': {
      zIndex: 100,
      backgroundColor: '#FFFFFF',
      position: 'relative',
      minHeight: 240,
      maxHeight: 240,
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      border: '1px solid rgb(38, 105, 169)',
    },
  },
  iframeContainerHide: {
    display: 'none !important',
  },

  graphscrollDisabled: {
    color: 'lightgray',
  },
  launchIcon: {
    marginTop: '9px',
    fontSize: '1.2rem',
    color: '#0056C8',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

export default NationalViewHistoricalDashboardStyles;
