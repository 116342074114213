/*
The following code defines baseURL of the dev application. 
*/

export const API_URL = (() => {
  let protocol = 'https://';
  let baseUrl = 'api.cshub.dev.mypepsico.com';
  let url = protocol.concat(baseUrl);
  return url.concat('/api/v1');
})();
