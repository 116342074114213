import { NoEncryption } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const HistoricalViewDashboardStyles = makeStyles((theme) => ({
  customerService: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  tabHeading: {
    padding: '0px 0px 0px 0px',
  },
  timePeriodContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timePeriodContain: {
    color: '#1C1C1C',
    fontSize: '1.2rem',
    opacity: 0.6,
  },
  dashboardContainer: {
    padding: '40px 0px 0px 0px',
  },
  RsmDashboardContainer: {
    padding: '0px 0px 0px 0px',
  },
  graphLegendPagination: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
  },

  tilesStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
    height: 'fit-content',
    position: 'relative',
  },
  rsmTilesStyle: {
    gap: 10,
  },
  iframeContainer: {
    width: '100%',
    minWidth: 251,
    maxWidth: 251,
    position: 'relative',
    minHeight: 165,
    maxHeight: 165,
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    paddingRight: '12px', // Keeps padding on the right for normal non-PGT tile
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)', // Initial shadow for normal tile
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '9px', // Permanent white border on the right
      height: '100%',
      backgroundColor: '#FFFFFF', // White for non-PGT
      borderRadius: '0 10px 10px 0',
    },
    '& .onHoverDisplayL4W': {
      display: 'none',
      opacity: 0,
    },
    '& .onHoverDisplay': {
      width: '100%',
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none', // Removes shadow from the normal tile on hover
      paddingRight: '0px', // Remove padding on hover to align with the expanded view
      '&::after': {
        display: 'none', // Remove the white border on hover
      },
      '& .onHoverHideL4W': {
        display: 'none',
        opacity: 0,
      },
      '& .onHoverDisplayL4W': {
        display: 'contents',
        opacity: 1,
        transition: 'opacity 1s linear',
      },
      '& .onHoverDisplay': {
        display: 'block',
      },
      '& .mainTileContainer': {
        zIndex: 100,
        paddingRight: '12px', // Keep padding for expanded view
        backgroundColor: '#FFFFFF',
        position: 'relative',
        minHeight: 255,
        maxHeight: 255,
        margin: '-1px',
        boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)', // Adds shadow to the expanded tile on hover
        border: '1px solid rgb(38, 105, 169)', // Light grey border only on expanded tile for non-PGT
        borderRadius: '10px',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '9px', // White border only on expanded card
          height: '100%',
          backgroundColor: '#FFFFFF', // White for non-PGT
          borderRadius: '0 10px 10px 0',
        },
      },
    },
  },

  pgt_iframeContainer: {
    width: '100%',
    minWidth: 251,
    maxWidth: 251,
    position: 'relative',
    minHeight: 165,
    maxHeight: 165,
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    paddingRight: '12px', // Keeps padding on the right for normal PGT tile
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)', // Initial shadow for normal tile
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '9px', // Permanent blue border on the right
      height: '100%',
      backgroundColor: 'rgb(38, 105, 169)', // Blue for PGT
      borderRadius: '0 10px 10px 0',
    },
    '& .onHoverDisplayL4W': {
      display: 'none',
      opacity: 0,
    },
    '& .onHoverDisplay': {
      width: '100%',
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none', // Removes shadow from the normal tile on hover
      paddingRight: '0px', // Remove padding on hover to align with the expanded view
      '&::after': {
        display: 'none', // Remove the blue border on hover
      },
      '& .onHoverHideL4W': {
        display: 'none',
        opacity: 0,
      },
      '& .onHoverDisplayL4W': {
        display: 'contents',
        opacity: 1,
        transition: 'opacity 1s linear',
      },
      '& .onHoverDisplay': {
        display: 'block',
      },
      '& .mainTileContainer': {
        zIndex: 100,
        paddingRight: '12px', // Keeps padding for expanded view
        backgroundColor: '#FFFFFF',
        position: 'relative',
        minHeight: 255,
        maxHeight: 255,
        margin: '-1px',
        boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)', // Adds shadow to the expanded tile on hover
        border: '1px solid rgb(38, 105, 169)', // Blue border only on expanded tile for PGT
        borderRadius: '10px',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '9px', // Blue border only on expanded card
          height: '100%',
          backgroundColor: 'rgb(38, 105, 169)', // Blue for PGT
          borderRadius: '0 10px 10px 0',
        },
      },
    },
  },

  tileActive: {
    position: 'relative',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
    top: 0,
    right: -1,
    height: '100%',
    border: '1px solid rgb(38, 105, 169)',
    borderRadius: '10px',
  },

  pgt_tileActive: {
    border: '1px solid rgb(38, 105, 169)',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
  },
  tableStyle: {
    padding: '0px 25px 0px 15px',
    background: '#ffffff',
    height: 'auto',
  },
  rsmTableStyle: {
    height: 'auto',
  },
  rankingsButton: {
    marginTop: '2px',
    border: '1px solid rgb(25, 118, 210)',
    padding: '4px 4px 4px 15px',
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    maxWidth: '200px',
    cursor: 'pointer',
    marginBottom: '20px',
  },

  rankText: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },
  rsmTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },

  tabLabel: {
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    color: 'black',
    textTransform: 'initial',
  },

  rsmIframeContainer: {
    display: 'none',
    minWidth: 251,
    maxWidth: 251,
    '@media screen and (min-width: 800px)': {
      minWidth: 288,
      maxWidth: 288,
    },
    '@media screen and (min-width: 1024px)': {
      minWidth: 294,
      maxWidth: 294,
    },
    '@media screen and (min-width: 1152px)': {
      minWidth: 334,
      maxWidth: 334,
    },
    '@media screen and (min-width: 1280px)': {
      minWidth: 334,
      maxWidth: 334,
    },
    '@media screen and (min-width: 1360px)': {
      minWidth: 312,
      maxWidth: 312,
    },
    '@media screen and (min-width: 1366px)': {
      minWidth: 312,
      maxWidth: 312,
    },
  },
  rsmDisplayTab: {
    display: 'block',
  },

  favoritePage: {
    display: 'none !important',
  },

  noDataAvailable: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },

  noDataAvailableGraph: {
    marginTop: 50,
  },

  RsmPBIContainer: {
    marginTop: 0,
  },
  RsmPBIContainerSummary: {
    marginTop: 0,
  },
  powerBIKPIName: {
    padding: '10px 0px 6px 0px',
    fontSize: '1.3rem',
    color: '#3D4043',
    fontWeight: '700',
  },
  viewMore: {
    fontSize: '0.7rem',
    color: '#004D8A',
    cursor: 'pointer',
    marginTop: '5px',
  },
  graphLegendContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    marginTop: 6,
    marginRight: 26,
  },
  graphScrollButton: {
    cursor: 'pointer',
  },
  graphscrollDisabled: {
    color: 'lightgray',
  },
  graphLegends: {
    color: '#004D8A',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  graphContainer: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D8D9DB',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
  },
  powerBiWidget: {
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollBehavior: 'smooth',
  },
  dropdown: {
    marginTop: 4,
    '& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
      margin: 0,
      marginRight: 2,
      height: 22,
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'rgb(243, 248, 255)',
      border: '1px solid #eaeaea',
      padding: 0,
      paddingLeft: 4,
      borderRadius: 0,
      '& .MuiAutocomplete-input': {
        fontSize: '1.2rem',
        padding: 10,
        color: '#004D8A',
      },

      '& fieldset': {
        fontSize: '1rem',
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
  noFavData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  infoContainer: {
    display: 'flex',
    height: '35px',
    justifyContent: 'right',
    cursor: 'pointer',
    padding: '0px',
  },
  exportContainer: {
    display: 'flex',
    marginTop: '5px',
    height: '35px',
    justifyContent: 'right',
    cursor: 'pointer',
    padding: '0px',
  },
  descriptionIcon: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    border: '1px solid rgb(25, 118, 210)',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  iconStyling: {
    height: '20px',
    width: '20px',
    paddingRight: '5px',
  },
  screenshot: {
    width: '100%',
    minWidth: 251,
    maxWidth: 251,
    position: 'relative',
    marginBottom: 100,
    // height: '100%',
    minHeight: 165,
    maxHeight: 165,
    border: '1px solid #e3e0d8',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    border: 'none',
    '& .onHoverHideL4W': {
      display: 'none',
      opacity: 0,
    },
    '& .onHoverDisplayL4W': {
      display: 'contents',
      opacity: 1,
      transition: 'opacity 1s linear',
    },
    '& .onHoverDisplay': {
      display: 'block',
      // transition: 'opacity 1s linear',
    },
    '& .mainTileContainer': {
      zIndex: 100,
      backgroundColor: '#FFFFFF',
      position: 'relative',
      // transition: 'all 0.5s ease-in-out',
      minHeight: 255,
      maxHeight: 255,
      borderRadius: '10px',
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      border: '1px solid rgb(38, 105, 169)',
    },
  },
  iframeContainerHide: {
    display: 'none !important',
  },
  backButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& h3': {
      display: 'flex',
      color: '#011F36',
      gap: 10,
      alignItems: 'baseline',
      '& img': {
        width: 10,
        cursor: 'pointer',
      },
    },
  },
}));

export default HistoricalViewDashboardStyles;
