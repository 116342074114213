/*
This component contains source code that defines filters in the RSM persona home page, favorites pages.
*/
import React, { useState, useEffect, useContext } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pingUser, postUser } from '../../actions/user';
import { some as _some, debounce as _debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Dropdown from '../atom/Dropdown';
import InputBox from '../atom/InputBox';
import MultiSelect from '../atom/MultipleSelect';
import FilterContext from '../context/FilterContext';
import AuthContext from '../context/AuthContext';
import LayoutStyles from '../styles/LayoutStyles';
import CustomizedSwitches from '../atom/CustomizedSwitches';

// Function for css
const useStyles = makeStyles(() => ({
  dropdown: {
    padding: '10px 0px 0px 0px ',
    marginRight: '10px',
  },
  dropdownSet: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  smallFont: {
    fontSize: '1rem',
  },
  dropdownList: {
    marginTop: 4,
    '& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
      margin: 0,
      marginRight: 2,
      height: 18,
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'rgb(243, 248, 255)',
      border: '1px solid #eaeaea',
      padding: 6,
      paddingLeft: 2,
      borderRadius: 0,
      '& .MuiAutocomplete-input': {
        fontSize: '1rem',
        padding: '2px',
        color: '#004D8A',
      },

      '& fieldset': {
        fontSize: '1rem',
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
  refreshTime: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 10,
  },
  hoverRefreshTime: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: 20,
  },
  refreshTimeText: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  refreshTimeDisplay: {
    fontSize: '1rem',
    fontWeight: '700',
  },
}));

const Filters = (props) => {
  const {
    user,
    getLocationType,
    getLocation,
    getRegion,
    getMultipleLocationType,
    refreshTime,
    countryLabel,
    locationLabel,
    dailyViewRefreshTime,
    locationTypeSingleDropDown,
    regionLabel,
    regionMultipleDropDown,
    locationTypeMultipleDropDown,
    locationMultipleDropDown,
    rsmDailyViewLocationTypeData,
    periodWeek,
    dateUpdated,
    historicalViewLastUpdated,
    locationSingleDropDown,
    regionSingleDropDown,
    nationalViewRegionData,
    hqSectionLocationTypeMultipleDropDown,
    regionName,
    siteName,
    hqLocationTypeSingleDropDown,
    startHoverExport,
    reportId,
    innerKpiId,
    showAlert,
    setLocationFilter,
    showDragFilter,
    dragKpi,
    setDragKpi,
  } = props;
  const classes = useStyles();
  const layoutClasses = LayoutStyles();
  const filterContext = useContext(FilterContext);
  const { updateProfileState } = useContext(AuthContext);
  const { location, getLocationsData, locationType, getLocationTypesData, region, getRegionsData, singleLocation } =
    filterContext;
  const [defaultLocationType, setDefaultLocationType] = useState(user.locationType);
  const [defaultMultipleLocationType, setDefaultMultipleLocationType] = useState([]);
  const [defaultMultipleLocationTypeAll, setMultipleLocationTypeAll] = useState(false);
  const [defaultMultipleLocation, setDefaultMultipleLocation] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState('');
  const [singleLocationTypeOption, setSingleLocationTypeOption] = useState([]);
  const [defaultRegion, setDefaultRegion] = useState([{ label: user.region, value: user.region }]);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [singleRegionOption, setSingleRegionOption] = useState([]);
  const [defaultSingleRegion, setDefaultSingleRegion] = useState('ALL');
  const [defaultMultipleRegionAll, setDefaultMultipleRegionAll] = useState(false);

  //Callback function

  useEffect(() => {
    if (locationTypeSingleDropDown) {
      getLocationTypesData(user.country, [regionName ? regionName : user.region]);
      setDefaultLocationType(rsmDailyViewLocationTypeData ? rsmDailyViewLocationTypeData : user.locationType);
    }
  }, [locationTypeSingleDropDown]);

  useEffect(() => {
    setDefaultSingleRegion(user.region !== null ? user.region : 'ALL');
    setDefaultLocationType(user.locationType !== null ? user.locationType : 'ALL');
  }, [user]);

  useEffect(() => {
    if (locationSingleDropDown) {
      getLocationsData(user.country, undefined, undefined, user.personaId, reportId);
      setDefaultLocation(singleLocation == '' ? user.location : singleLocation);
    }
  }, [locationSingleDropDown]);

  useEffect(() => {
    if (regionMultipleDropDown && user.persona.layoutId === 3) {
      if (!isEmpty(nationalViewRegionData)) {
        setDefaultRegion(nationalViewRegionData);
      } else {
        setDefaultMultipleRegionAll(true);
      }
    }
  }, [regionMultipleDropDown]);
  useEffect(() => {
    if (regionMultipleDropDown || regionSingleDropDown) {
      getRegionsData(user.country, user.personaId);
    }
  }, [regionMultipleDropDown, regionSingleDropDown]);

  useEffect(() => {
    if (locationTypeMultipleDropDown) {
      let ltd = rsmDailyViewLocationTypeData ? rsmDailyViewLocationTypeData : user.locationType;
      if (ltd == 'ALL') {
        setMultipleLocationTypeAll(true);
      } else {
        setDefaultMultipleLocationType([{ label: ltd, value: ltd }]);
        setMultipleLocationTypeAll(false);
      }
    }
  }, [locationTypeMultipleDropDown]);

  useEffect(() => {
    if (hqSectionLocationTypeMultipleDropDown && region.length > 0) {
      let ltd = rsmDailyViewLocationTypeData ? rsmDailyViewLocationTypeData : user.locationType;
      if (ltd == 'ALL' || ltd == null) {
        setMultipleLocationTypeAll(true);
      } else {
        setDefaultMultipleLocationType([{ value: ltd, label: ltd }]);
        setMultipleLocationTypeAll(false);
      }
    }
  }, [hqSectionLocationTypeMultipleDropDown, region]);

  useEffect(() => {
    if (hqLocationTypeSingleDropDown && region.length > 0) {
      let data = [];
      region.map((item) => {
        data.push(item.value);
      });
      getLocationTypesData(user.country, data);
      setDefaultLocationType(rsmDailyViewLocationTypeData ? rsmDailyViewLocationTypeData : 'ALL');
      getLocationType(rsmDailyViewLocationTypeData ? rsmDailyViewLocationTypeData : 'ALL');
    }
  }, [hqLocationTypeSingleDropDown, region]);

  // Function for add ALL option into location type list
  useEffect(() => {
    let newData = [
      {
        label: 'ALL',
        value: 'ALL',
      },
      ...locationType,
    ];
    setSingleLocationTypeOption(newData);
  }, [locationType]);

  // Function for add ALL option into region list
  useEffect(() => {
    let newData = [
      {
        label: 'ALL',
        value: 'ALL',
      },
      ...region,
    ];
    setSingleRegionOption(newData);
  }, [region]);

  // Call Location Type select function
  const onSelectLocationType = (locationType) => {
    if (locationType.value) {
      setDefaultLocationType(locationType.value);
      if (user.persona.layoutId === 2) {
        saveUserProfile('locationType', locationType.value);
      }
      getLocationType(locationType.value);
    }
  };

  // Call Region single select select function
  const onSelectSingleRegion = (regionData) => {
    if (regionData.value) {
      setDefaultSingleRegion(regionData.value);
      let data = [];
      if (regionData.value === 'ALL') {
        region.map((item) => {
          data.push(item.value);
        });
        getLocationTypesData(user.country, data);
      } else {
        getLocationTypesData(user.country, [regionData.value]);
      }
      if (user.persona.layoutId === 2) {
        saveUserProfile('region', regionData.value);
      } else {
        getRegion(regionData.value);
      }
    }
  };

  // Function for select multiple region dropdown list
  const onSelectMultipleRegion = (region) => {
    if (locationMultipleDropDown) {
      getLocation([]);
    }
    if (isEmpty(region)) {
      if (locationMultipleDropDown) {
        setDefaultMultipleLocationType([]);
        getMultipleLocationType([]);
      } else {
        setDefaultLocationType('');
      }
    }

    if (firstLoaded) {
      setDefaultMultipleLocationType([]);
      getMultipleLocationType([]);
      setMultipleLocationTypeAll(false);
    } else if (!isEmpty(defaultMultipleLocationType)) {
      setFirstLoaded(true);
    }
    let joinData = [];
    let joinSetData = [];

    region.map((data) => {
      joinData.push({ value: data.value, label: data.value });
      joinSetData.push(data.value);
    });
    setDefaultRegion(joinData);
    getRegion(joinSetData);

    if (!isEmpty(joinSetData) && joinSetData[0] !== null) {
      getLocationTypesData(user.country, joinSetData, innerKpiId);
    }
  };

  // Function for select multiple location type dropdown list
  const onSelectMultipleLocationType = (locationType, allSelected) => {
    if (!isEmpty(region) && regionMultipleDropDown) {
      setDefaultMultipleLocation([]);
      if (locationMultipleDropDown) {
        getLocation([]);
      }
      let joinData = [];
      let joinSetData = [];
      locationType.map((data) => {
        joinData.push({ value: data.value, label: data.value });
        joinSetData.push(data.value);
      });
      setDefaultMultipleLocationType(joinData);
      getMultipleLocationType(joinSetData, allSelected);
      let regionData = [];
      defaultRegion.map((item, index) => {
        regionData.push(item.value);
      });
      if (locationMultipleDropDown) {
        getLocationsData(user.country, regionData, joinSetData, user.personaId, reportId, allSelected ? 'ALL' : '');
      }
    } else {
      setDefaultMultipleLocation([]);
    }
  };

  // Function for select multiple location type dropdown list for HQ Dashboard
  const onSelectMultipleHQLocationType = (locationType) => {
    let joinData = [];
    let joinSetData = [];
    locationType.map((data) => {
      joinData.push({ value: data.value, label: data.value });
      joinSetData.push(data.value);
    });
    setDefaultMultipleLocationType(joinData);
    getMultipleLocationType(joinSetData);
  };

  // Location filter to update the selected location for export ranking table
  useEffect(() => {
    let joinSetData = [];
    if (location.length > 0) {
      location.forEach((data) => {
        joinSetData.push(data.value);
      });
      if (props.getLocation && user.persona.layoutId === 2) {
        props.getLocation(joinSetData);
      }
    }
  }, [location]);

  // Call Multiple Location select function
  const onSelectMultipleLocation = (location) => {
    let joinData = [];
    let joinSetData = [];
    location.map((data) => {
      joinData.push({ value: data.value, label: data.value });
      joinSetData.push(data.value);
    });
    setDefaultMultipleLocation(joinData);
    setLocationFilter(joinSetData);
  };

  // Call Single Location  select function
  const onSelectSingleLocation = (location) => {
    if (location.value) {
      setDefaultLocation(location.value);
      getLocation(location.value);
      if (user.persona.layoutId === 1) {
        saveUserProfile('location', location.value);
      }
    }
  };

  // Save and Update the user details
  const saveUserProfile = async (key, value) => {
    let userData;
    if (key === 'region') {
      userData = { ...user, [key]: value, locationType: 'ALL' };
    } else {
      userData = { ...user, [key]: value };
    }
    const respData = await postUser(userData);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    } else {
      const UserData = await pingUser({ userName: user.email });
      updateProfileState(UserData);
    }
  };

  return (
    <Grid item className={layoutClasses.filterContainer}>
      <Typography variant='h5' component={'h3'} style={{ fontWeight: '600' }}>
        Filters
      </Typography>
      <div className={classes.dropdownSet}>
        {countryLabel && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Country' value={user.country} readOnly={true} />
          </Grid>
        )}
        {regionLabel && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Region' value={regionName ? regionName : user.region} readOnly={true} />
          </Grid>
        )}
        {regionSingleDropDown && singleRegionOption.length > 0 && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Region' readOnly={true} />
            <Dropdown
              options={singleRegionOption}
              getOptionLabel={(option) => (option.value ? option.value : defaultSingleRegion)}
              isOptionEqualToValue={(option, value) => option.value === value}
              onChange={(_e, value) => onSelectSingleRegion(value)}
              className={classes.dropdownList}
              value={defaultSingleRegion}
              popperbottom={33}
            />
          </Grid>
        )}
        {regionMultipleDropDown && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Region' readOnly={true} />
            <MultiSelect
              limitTags={1}
              size='small'
              items={region}
              selectAllLabel='ALL'
              onSelectChange={onSelectMultipleRegion}
              defaultData={defaultRegion}
              className={classes.dropdownList}
              defaultAllSelected={defaultMultipleRegionAll}
            />
          </Grid>
        )}
        {(locationTypeSingleDropDown || hqLocationTypeSingleDropDown) && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Location Type' readOnly={true} />
            <Dropdown
              options={singleLocationTypeOption}
              getOptionLabel={(option) => (option.value ? option.value : defaultLocationType)}
              isOptionEqualToValue={(option, value) => option.value === value}
              onChange={(_e, value) => onSelectLocationType(value)}
              className={classes.dropdownList}
              value={defaultLocationType}
              popperbottom={33}
            />
          </Grid>
        )}
        {locationTypeMultipleDropDown && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Location Type' readOnly={true} />
            <MultiSelect
              limitTags={1}
              size='small'
              items={locationType}
              selectAllLabel='ALL'
              onSelectChange={onSelectMultipleLocationType}
              defaultData={defaultMultipleLocationType}
              className={classes.dropdownList}
              defaultAllSelected={defaultMultipleLocationTypeAll}
            />
          </Grid>
        )}
        {locationMultipleDropDown && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Location' readOnly={true} />
            <MultiSelect
              limitTags={1}
              size='small'
              items={location}
              selectAllLabel='ALL'
              onSelectChange={onSelectMultipleLocation}
              defaultData={defaultMultipleLocation}
              className={classes.dropdownList}
              defaultAllSelected={true}
            />
          </Grid>
        )}
        {locationSingleDropDown && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Location' readOnly={true} />
            <Dropdown
              options={location}
              getOptionLabel={(option) => (option.value ? option.value : defaultLocation)}
              isOptionEqualToValue={(option, value) => option.value === value}
              onChange={(_e, value) => onSelectSingleLocation(value)}
              className={classes.dropdownList}
              value={defaultLocation}
              popperbottom={33}
            />
          </Grid>
        )}
        {locationLabel && (
          <Grid item xl={12} xs={12} className={classes.dropdown}>
            <InputBox label='Location' value={siteName ? siteName : user.location} readOnly={true} />
          </Grid>
        )}
        {showDragFilter && (
          <Grid item xl={12} xs={12} style={{ display: 'flex' }}>
            <Box>
              <InputBox label='Reorder KPIs' readOnly={true} />
            </Box>
            <Box ml={2}>
              {' '}
              {/* Use Material-UI spacing (equivalent to 4 * 8px = 32px) */}
              <CustomizedSwitches
                key={`switch-ytd-period`}
                handleChange={(e) => setDragKpi(e.target.checked)}
                checked={dragKpi}
              />
            </Box>
          </Grid>
        )}
      </div>
      {dailyViewRefreshTime && (
        <Grid item xl={12} xs={12} className={classes.refreshTime}>
          <span className={classes.refreshTimeText}>Dashboard Refresh Time</span>
          <Typography variant='h6' component={'span'} className={classes.refreshTimeDisplay}>
            {refreshTime ? `${refreshTime} CST` : ''}
          </Typography>
        </Grid>
      )}
      {historicalViewLastUpdated && dateUpdated !== '' && (
        <Grid item xl={12} xs={12} className={startHoverExport ? classes.hoverRefreshTime : classes.refreshTime}>
          <span className={classes.refreshTimeText}>Data Last Updated : {dateUpdated}</span>
          <Typography variant='h6' component={'span'} className={classes.refreshTimeDisplay}>
            (through {periodWeek ? `${periodWeek[0]} ${periodWeek[1]}x${periodWeek[2]}` : ''})
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

Filters.propTypes = {
  user: PropTypes.object,
  getRegion: PropTypes.func,
  setDragKpi: PropTypes.func,
};

Filters.defaultProps = {
  user: null,
  refreshTime: '',
  countryLabel: false,
  locationLabel: false,
  dailyViewRefreshTime: false,
  locationTypeSingleDropDown: false,
  regionLabel: false,
  regionMultipleDropDown: false,
  locationTypeMultipleDropDown: false,
  locationMultipleDropDown: false,
  rsmDailyViewLocationTypeData: '',
  periodWeek: null,
  dateUpdated: null,
  historicalViewLastUpdated: false,
  locationSingleDropDown: false,
  regionSingleDropDown: false,
  nationalViewRegionData: '',
  hqSectionLocationTypeMultipleDropDown: false,
  regionName: '',
  siteName: '',
  hqLocationTypeSingleDropDown: false,
  startHoverExport: false,
  reportId: null,
  innerKpiId: '',
  getLocation: false,
  dragKpi: false,
  showDragFilter: false,
};

export default Filters;
